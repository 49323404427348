
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})
export default class EPosta extends Mixins(SimpleInputMixin) {
  @Prop() required !: any;
  @Prop() error !: boolean;
  @Prop() errorMessages !: string;
  @Prop() id !: string;
  @Prop() label !: string;
  @Prop() neededEposta !: true;
  mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{13}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  turkishCharRegex = /[ğüşıöçĞÜŞİÖÇ]/;

  rules = [
      (value: any) => {
          if (this.localValue && this.localValue.match(this.mailRegex)) {
              return true;
          }
          return !!value || "Lütfen bu alanı doldurun.";
      },

      (value: any) => {
          if (value) {
              if (this.turkishCharRegex.test(value)) {
                  return "Türkçe karakterler kullanmayın.";
              }
              return this.mailRegex.test(value) || "Hatalı E-Posta";
          }
          return true;
      },
  ];

  get localId() {
    if (!this.neededEposta){
      return '';
    }else{
      this.id ?? 'neededEposta'
    }
  }

  validate() {
    this.$emit("value", this.localValue);
  }

  @Watch('localValue')
  onLocalValChange() {
    this.input();
  }

  get isRequired() {
    return this.required || this.required === "";
  }
}
