
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import {DilekceAlanlari} from "@/enum/DilekceAlanlari";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";

@Component({
  components: {TcKimlikNumarasi, IcraDairesiPicker, Dates, DosyaEsasNumarasi}
})
export default class DilekceOlusturForm extends Mixins(SimpleInputMixin) {
  dilekceAlanlari:any = [];
  degerler:any = [];
  dilekceAlanlariYeni=DilekceAlanlari
  bugununTarihi = new Date()
  @Watch('localValue')
  onLocalValueChange() {
    if (typeof this.localValue.alanlar != "object")
      this.localValue.alanlar = JSON.parse(this.localValue.alanlar);
  }

  @Watch('degerler')
  onDegerlerChange() {
    let veri:any = {};
    this.localValue.alanlar.forEach((alan: any, index: number) => {
      veri[this.dilekceAlanlari.find((v:any) => v.id == alan).kod] = this.degerler[index];
    });
    this.$emit('manuel-dilekce', veri);
  }

  mounted() {
    this.load();
    this.onLocalValueChange();
  }

  load() {
    this.$http
        .get("/api/v1/dilekce-alan")
        .then((response: any) => {
          this.dilekceAlanlari = response.data;
        });
  }

  label(id: number) {
    let isim;
    isim = this.dilekceAlanlari.find((v:any) => v.id == id).isim;
    return isim + ' Giriniz';
  }
  baslik(id: number) {
    let isim;
    isim = this.dilekceAlanlari.find((v:any) => v.id == id).isim;
    return isim;
  }
}
