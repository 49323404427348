
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {TakipDurumu} from "@/enum/TakipDurumu";

@Component({
  directives: {
    mask
  }
})
export default class DosyaEsasNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() label !: string;
  @Prop() placeholder !: string;
  @Prop() rules !: any;
  @Prop() dense !: string | boolean;
  @Prop() hideDetails !: string | boolean;
  @Prop() disabled !: string | boolean;

  maskStr = "####/#######";

  get isDense() {
    return this.dense || this.dense === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  validate() {
    this.$emit("value", this.localValue);
  }
}
