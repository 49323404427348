
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {AdresTuru} from "@/enum/AdresTuru";
import {AdresTuruEntity} from "@/entity/AdresTuruEntity";

@Component({
  components: {DatabasePicker},
})
export default class AdresTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() rules!: string;
  @Prop() color: any;
  @Prop() label !: string;
  @Prop() mernisAdresiGosterme!: boolean | string;
  @Prop() askerlikAdresiGosterme!: boolean | string;
  adresTuru =AdresTuru



  get filter(){
    let ids:Array<number> = []
    if(this.isMernisAdresiGosterme){
      ids.push(this.adresTuru.mernis)
    }
    if (this.isAskerlikAdresiGosterme){
      ids.push(this.adresTuru.askerlik)
    }
    if (ids.length!=0){
      return (items:Array<AdresTuruEntity>)=>(items.filter((item:any)=>ids.indexOf(item.id)<0))
    }else{
      return null
    }
  }

  get isMernisAdresiGosterme() {
    return this.mernisAdresiGosterme || this.mernisAdresiGosterme === "";
  }
  get isAskerlikAdresiGosterme() {
    return this.askerlikAdresiGosterme || this.askerlikAdresiGosterme === "";
  }
}
