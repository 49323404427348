
import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DilekcePicker from "@/components/pickers/DilekcePicker.vue";

@Component({
  components: {DilekcePicker}
})
export default class TakipDilekceOlusturForm extends Mixins(SimpleInputMixin) {
}
