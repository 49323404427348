

import {Component, Mixins, Prop} from "vue-property-decorator";
import {Udf} from '@/classes/HtmlToUdf';
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import 'jodit/build/jodit.min.css'
import {JoditEditor} from 'jodit-vue'
import FormWrapper from "@/components/FormWrapper.vue";
import DilekceTipPicker from "@/components/pickers/DilekceTipPicker.vue";
import html2pdf from "../../../node_modules/html2pdf.js/dist/html2pdf.js";
import {DilekceEntity} from "@/entity/DilekceEntity";

@Component({
  components: {
    DilekceTipPicker,
    FormWrapper,
    JoditEditor,
  }
})
export default class DilekceEditor extends Mixins(ObjectInputMixin) {
  @Prop() download!: boolean | string;
  @Prop() error!: boolean;

  specialFieldsStatus: Boolean = false;
  search: string = "";
  dilekceAlanlari: Array<DilekceEntity> = [];
  isPreview: boolean = false;
  eskiDilekceIsim='';

  config: any = {
    i18n: 'tr',
    language: 'tr',
    buttons: [
      'bold',
      'underline',
      'italic',
      '|',
      'fontsize',
      'ul',
      'ol',
      '|',
      'align',
      'table',
      '|',
      'undo',
      'redo',
      '|',
      'onizle',
      'print',
      'udf',
      'word',
      'pdf',
      '|',
      'alanlar',
    ],
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    controls: {
      onizle: {
        name: 'Önizle',
        iconURL: 'https://img.icons8.com/material-rounded/24/000000/visible.png',
        exec: (editor: any) => {
          this.showPreview();
        }
      },
      udf: {
        name: 'Udf',
        exec: (editor: any) => {
          this.saveUdf();
        }
      },
      pdf: {
        name: 'Pdf',
        iconURL: 'https://img.icons8.com/material/24/000000/export-pdf.png',
        exec: (editor: any) => {
          this.savePDF();
        }
      },
      word: {
        name: 'Word',
        iconURL: 'https://img.icons8.com/material/24/000000/ms-word--v2.png',
        exec: (editor: any) => {
          this.saveWord();
        }
      },
      alanlar: {
        name: 'Alanlar',
        exec: (editor: any) => {
          this.specialFieldFunc();
        }
      }
    }
  }
  onClose(){
    this.$emit("onClose");

  }
  get isDownload() {
    return this.download || this.download === "";
  }

  get filtrelenmisDilekceAlanlari() {
    const search = this.search.toLowerCase().trim();
    if (!search) return this.dilekceAlanlari;
    return this.dilekceAlanlari.filter(c => c.isim.toLowerCase().indexOf(search) > -1);
  }

  showPreview() {
    this.isPreview = true;
  }

  specialFieldFunc() {
    if (this.specialFieldsStatus) {
      this.specialFieldsStatus = false
    } else {
      this.specialFieldsStatus = true
    }
  }

  filterBlur() {
    let elem = <HTMLTextAreaElement>document.getElementsByClassName("jodit-wysiwyg")[0];
    this.selectAll(elem);
  }

  appendText(item: any) {
    let selectedItem = "{{" + item.kod + "}}"
    let elem = <HTMLInputElement>document.getElementsByClassName("jodit-wysiwyg")[0];
    this.selectAll(elem);
    window.document.execCommand("insertText", false, selectedItem);
    this.specialFieldsStatus = false;
  }

  selectAll(el: any) {
    //el.select();
    el.focus();
  };

  findAlanlar() {
    this.localValue.alanlar = [];
    this.dilekceAlanlari.forEach((dilekceAlani: any) => {
      this.localValue.sablon.includes("{{" + dilekceAlani.kod + "}}") ? this.localValue.alanlar.push(dilekceAlani.id) : null;
    });
    this.localValue.alanlar = JSON.stringify(this.localValue.alanlar);
    this.input();
  }

  mounted() {
    this.eskiDilekceIsim=this.localValue.isim;
    this.load();
  }

  load() {
    this.$http
        .get("/api/v1/dilekce-alan")
        .then((response: any) => {
          this.dilekceAlanlari = response.data;
        });
  }

  saveUdf() {
    new Udf(this.localValue.sablon,[]).generate(this.localValue.isim)
  }

  saveWord() {
    let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Save File</title></head><body>";
    let footer = "</body></html>";
    let sourceHTML = header + this.localValue.sablon + footer;
    let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    let fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = this.localValue.isim + '.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
    this.$emit("onClose");
  }

  async savePDF() {
    const element = this.localValue.sablon;
    let options = {
      margin: 1,
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2},
      jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
    };
    await html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf:any) => {
      const totalPages = pdf.internal.getNumberOfPages()
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i)
        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88),
            (pdf.internal.pageSize.getHeight() - 0.3))
      }
    }).save(this.localValue.isim + '.pdf');
    this.$emit("onClose");
  }
}
