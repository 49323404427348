

import {Component, Vue} from "vue-property-decorator";
import DilekceList from "@/components/lists/DilekceList.vue";

@Component({
  components: {
    DilekceList,
  },
})
export default class DilekceView extends Vue {

}
