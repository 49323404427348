import {IlceEntity} from "@/entity/IlceEntity";

export class IlEntity {
    id!: number;
    kod!: number;
    isim!: string;
    ilceler !: IlceEntity;
    created_at!: Date;
    updated_at!: Date;
    deleted_at!: Date;
    /*
    id: number | null = null;
    plakaKodu: number | null = null;
    siraNo: number | null = null;
    isim: string | null = null;
    ilceler: Array<IlceEntity> = [];
    icraDaireleri: Array<IcraDairesiEntity> = [];
    */
}
