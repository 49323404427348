

import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {DilekceEntity} from "@/entity/DilekceEntity";

@Component({
  components: {}
})
export default class DilekcePicker extends Mixins(SimpleInputMixin) {
  items: Array<DilekceEntity> =[]

  get rules(){
    if (this.localValue.length==0)
      return 'Lütfen bu alanı doldurun.';
    else
      return true;
  }

  mounted() {
    this.load();
  }

  load(){
    this.$http.get('/api/v1/dilekce').then((response)=>(this.items=response.data));
  }

  remove(item: any){
    this.localValue.splice(this.localValue.indexOf(item.id), 1)
  }
}
