

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import {DilekceTipi, DilekceTipiLabel} from "@/enum/DilekceTipi";

@Component({
  components: {EnumPicker}
})
export default class DilekceTipPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() label!: string;

  dilekceTipi=DilekceTipi;
  dilekceTipiLabel=DilekceTipiLabel;
}
