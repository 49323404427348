
import {Component, Prop, Vue} from "vue-property-decorator";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";

@Component({
  components: {},
})
export default class IcraDairesiList extends Vue {
  items: IcraDairesiEntity[] = [];
  search: string = "";
  onFly = false;
  mahkeme:IcraDairesiEntity[] = [];

  headers = [
    {text: "İcra Dairesi", align: "start", value: "isim"},
    {text: "İl", value: "sehir.isim"},
    {text: "İlçe", value: "ilce.isim"}
  ];
  @Prop({})
  value!: IcraDairesiEntity;

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/icra-dairesi/").then((response) => (this.items = response.data));
  }

  onItemActivated() {
    if (this.mahkeme.length > 0) {
      this.onFly = true;
      this.mahkeme[0].aktif_mi = true;
      this.$http.put("/api/v1/icra-dairesi/" + this.mahkeme[0].id, this.mahkeme[0]).then(() => {
        this.load()
        this.$emit("input", this.mahkeme[0]);
        this.$emit("close");
        this.mahkeme = [];
      }).finally(() => (this.onFly = false));
    }
  }

  close() {
    this.$emit("cancel");
    this.$emit("close");
  }
}
