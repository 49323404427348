export enum DilekceAlanlari{

  alacakliAdSoyad = 1,
  alacakliTc = 2,
  alacakliAdresi = 3,
  alacakliVekiliAdSoyad = 4,
  borcluVekiliAdSoyad = 5,
  borcluAdSoyad = 6,
  borcluKurumIsim = 7,
  borcluAdresi = 8,
  borcluVekiliAdSoyad = 9,
  icraDairesi = 10,
  takipTipi = 11,
  takipYolu = 12,
  dosyaEsasNo = 13,
  takipTarihi = 14,
  alacakliKurumAdi = 15,
  bugununTarihi = 16,
  hacizTarihi = 17,
  borcluTc = 18,
  alacakliVekilAdresi = 19,
  takipToplami = 20,
  masrafToplami = 21,
  harcToplami = 22,
  vekaletUcreti = 23,
  genelToplam = 24,
  tahsilatToplami = 25,
  kalanTutar = 26,
  tebligTarihi = 27,
  vadeTarihi = 28,
  borcluVekiliAdresi = 29
}

export enum DilekceAlanlariLabel{
  "Alacaklı Adı Ve Soyadı" = 1,
  "Alacaklı T.C. Kimlik No" = 2,
  "Alacaklı Adresi" = 3,
  "Alacaklı Vekili Ad Ve Soyad" = 4,
  "Borçlu Vekilinin Adı Ve Soyadı" = 5,
  "Borçlu Adı Ve Soyadı" = 6,
  "Borçlu Kurum İsim" = 7,
  "Borçlu  Adresi" = 8,
  "Borçlu Vekilinin Adı Ve Soyadı" = 9,
  "İcra Dairesi" = 10,
  "Takip Tipi" = 11,
  "Takip Yolu" = 12,
  "Dosya  Esas No" = 13,
  "Takip Tarihi" = 14,
  "Alacaklı Kurum Adı" = 15,
  "Bugünün Tarihi" = 16,
  "Haciz Tarihi" = 17,
  "Borçlu TC Kimlik No" = 18,
  "Alacakli Vekili Adresi" = 19,
  "Takip Toplamı" = 20,
  "Masraf Toplamı" = 21,
  "Harç Toplamı" = 22,
  "Vekalet Ücreti" = 23,
  "Genel Toplam" = 24,
  "Tahsilat Toplamı" = 25,
  "Kalan_tutar" = 26,
  "Teblig Tarihi" = 27,
  "Vade Tarihi" = 28,
  "Borçlu Vekilin Adresi" = 29
}