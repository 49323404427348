export enum DilekceTipi {
    haciz = "haciz",
    tebligat = "tebligat",
    yonetmelik_ornekleri = "yonetmelik_ornekleri",
    talepler = "talepler",
    muzekkere_ve_talimatlar = "muzekkere_ve_talimatlar",
    dava_dilekceleri = "dava_dilekceleri",
    kendi_dilekcelerim = "kendi_dilekcelerim"
}

export enum DilekceTipiLabel {
    haciz = "Haciz",
    tebligat = "Tebligat",
    yonetmelik_ornekleri = "Yönetmelik Örnekleri",
    talepler = "Talepler",
    muzekkere_ve_talimatlar = "Müzekkere ve Talimatlar",
    dava_dilekceleri = "Dava Dilekceleri",
    kendi_dilekcelerim = "Kendi Dilekçelerim"
}
