
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlEntity} from "@/entity/IlEntity";

@Component({
  components: {DatabasePicker},
})
export default class SehirPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: any;
  @Prop() rules!: string;
  @Prop() label!: any;
  @Prop() color!: any;
  @Prop({default : true}) required!: boolean;
  @Prop() disabled!: boolean | string;
  @Prop({default: true}) clearable!: boolean;

  items: any;

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  onSehirChange(v: IlEntity) {
    this.$emit('sehir-adi', v.isim);
    this.input()
  }
}
