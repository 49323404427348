
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {KisiSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";

@Component({
  directives: {
    mask
  },
})
export default class TcKimlikNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() required: any;
  @Prop() error !: boolean;
  @Prop() errorMessages !: string;
  @Prop() placeHolder !: string;
  @Prop() uyapSorgula !: string | boolean;
  //@Prop() labelNew !: string;
  @Prop({default:'TC Kimlik No'}) label !: string;


  mask = "###########";
  rules = [
    (value: any) => {
      return (!this.isRequired || !!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      return (!this.value || value == "" || value?.toString().length == 11) || "11 karakter olmalıdır.";
    },
    (value: any) => {
      return (!this.value || value === "" || this.$helper.tcKimlikNoValidate(value) === true) || "TC Kimlik No doğru değil.";
    },
  ];

  // get label(){
  //   if(this.labelNew!=null){
  //     return this.labelNew;
  //   }else{
  //     return "TC Kimlik No";
  //   }
  //
  // }

  get isRequired() {
    return this.required || this.required === "";
  }

  get isUyapSorgulaButton() {
    return this.uyapSorgula || this.uyapSorgula === "";
  }

  async sorgula() {
    let talep: KisiSorgulamaTalep= {tcKimlikNo: this.localValue}
    let cevap = await this.$uyap.KisiSorgula().run(talep);
    this.$emit('uyap', cevap);
  }

  validate() {
    this.$emit("value", this.localValue);
  }
}
