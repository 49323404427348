import {DilekceTipi} from "@/enum/DilekceTipi";
import {DilekceSecenekEntity} from "@/entity/DilekceSecenekEntity";

export class DilekceEntity {
    id!: number;
    alanlar !: [];
    dilekce_tipi!: DilekceTipi;
    isim!: string;
    sablon: string = '';
    secenekler !: Array<DilekceSecenekEntity>;
    standart_mi!: boolean;
    favori_mi: boolean = false;
}
