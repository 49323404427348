var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form-wrapper',{staticClass:"adres-form-style",attrs:{"action":_vm.localAction,"hideCloseButton":_vm.hideButton,"hideSubmitButton":_vm.hideButton,"success-on-validate":_vm.successOnValidate}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('span',{staticClass:"input-baslik-style"},[_vm._v("Adres Türü ")]),_c('adres-turu-picker',{staticClass:"mt-5",attrs:{"rules":_vm.nonRequired ? []:[(v) => !!v || 'Lütfen bu alanı doldurun.'],"askerlik-adresi-gosterme":_vm.yabanciUyruklu,"return-id":""},on:{"input":_vm.onAdresTuruChange},model:{value:(_vm.localValue.adres_tur_id),callback:function ($$v) {_vm.$set(_vm.localValue, "adres_tur_id", $$v)},expression:"localValue.adres_tur_id"}})],1),(!(_vm.localValue.adres_tur_id === _vm.adresTuru.mernis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.mersis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiev ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiis))?_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('span',{staticClass:"input-baslik-style"},[_vm._v("Şehir ")]),_c('sehir-picker',{staticClass:"mt-5",attrs:{"label":'',"rules":_vm.nonRequired ? []:[(v) => !!v || 'Lütfen bu alanı doldurun.'],"return-id":""},on:{"input":_vm.input},model:{value:(_vm.localValue.sehir_id),callback:function ($$v) {_vm.$set(_vm.localValue, "sehir_id", $$v)},expression:"localValue.sehir_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[(!(_vm.localValue.adres_tur_id === _vm.adresTuru.mernis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.mersis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiev ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiis) &&
                _vm.isFormYazdir)?_c('ilce-picker',{attrs:{"label":'',"il":_vm.localValue.sehir_id,"rules":_vm.nonRequired ? []:[(v) => !!v || 'Lütfen bu alanı doldurun.'],"return-object":""},on:{"input":_vm.input},model:{value:(_vm.secilenIlce),callback:function ($$v) {_vm.secilenIlce=$$v},expression:"secilenIlce"}}):_vm._e(),(!(_vm.localValue.adres_tur_id === _vm.adresTuru.mernis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.mersis ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiev ||
                _vm.localValue.adres_tur_id === _vm.adresTuru.yurtdisiis) &&
                !_vm.isFormYazdir)?_c('ilce-picker',{staticClass:"mt-5",attrs:{"label":'',"il":_vm.localValue.sehir_id,"rules":_vm.nonRequired ? [] :[(v) => !!v || 'Lütfen bu alanı doldurun.'],"return-id":""},on:{"input":_vm.input},model:{value:(_vm.localValue.ilce_id),callback:function ($$v) {_vm.$set(_vm.localValue, "ilce_id", $$v)},expression:"localValue.ilce_id"}}):_vm._e()],1),(_vm.localValue.adres_tur_id !== _vm.adresTuru.mernis && _vm.localValue.adres_tur_id !== _vm.adresTuru.mersis)?_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('span',{staticClass:"input-baslik-style"},[_vm._v("Posta Kodu ")]),_c('posta-kodu',{staticClass:"mt-5",attrs:{"label":''},on:{"input":_vm.input},model:{value:(_vm.localValue.posta_kodu),callback:function ($$v) {_vm.$set(_vm.localValue, "posta_kodu", $$v)},expression:"localValue.posta_kodu"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.localValue.adres_tur_id !== _vm.adresTuru.mernis && _vm.localValue.adres_tur_id !== _vm.adresTuru.mersis )?_c('v-textarea',{attrs:{"rules":[_vm.adresBilgisiRule, _vm.requiredRule].flat(),"label":"Adres Bilgisi *","background-color":"#F2F2F7","name":"input-7-4","outlined":"","dense":""},on:{"input":_vm.input},model:{value:(_vm.localValue.adres),callback:function ($$v) {_vm.$set(_vm.localValue, "adres", $$v)},expression:"localValue.adres"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }